@import "src/components/ui.module.scss";

.go_to_homepage_button {
  padding-right: 10px;
}

.go_to_homepage_button > button {
  height: 44px;

  text-transform: none;

  border-radius: 8px;
}

.error_title {
  margin-bottom: 20px;

  font-size: 50px;
  font-weight: bold;
}

.text_body {
  max-width: 500px;
  margin-bottom: 20px;

  text-align: center;

  color: var(--color-petrol-light);

  font-size: 18px;

  line-height: 24px;
}

.wrapper {
  align-items: center;
  justify-content: center;
}

.buttons_wrapper {
  margin-bottom: 25px;
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 5% 0;

  font-family: inherit;
}

.error_logo {
  width: 521px;
  height: 111px;

  border-top-left-radius: calc(var(--global-radius) * 2);
  border-top-right-radius: calc(var(--global-radius) * 2);

  background: url("images/logo-10j.png") center center no-repeat;
  background-size: cover;
}
