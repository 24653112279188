@import "src/components/ui.module.scss";
:root {
  --dialog-color-title: var(--palette-blue-grey-800);
}

.title {
  color: var(--dialog-color-title);

  font-size: 20px;
}

.doorbell_form_section {
  display: flex;
  max-width: 58em;
}
.doorbell_form_section__tutorial_links {
  background-color: var(--color-gray-50);
  display: flex;
  flex-direction: column;
  flex: 0.8;
  padding: 1rem;
}

.doorbell_form_section__tutorial_links h3 {
  color: var(--color-petrol-dark);
  margin-bottom: 14px;
}

.doorbell_form_section__tutorial_links a {
  display: block;
  color: var(--color-gray);
  margin-bottom: 14px;
}
.doorbell_form_section__form {
  flex: 1.2;
  margin: 1rem;

  ol {
    padding-left: 2rem;

    li {
      margin-top: 1rem;
    }

    li::marker,
    .li-title {
      font-weight: bold;
    }
  }
}

.doorbell_form_section__helper_text {
  margin-top: 1em;
  display: flex;
  direction: row;
  color: var(--color-leer-blue);

  i {
    padding-top: 0.3em;
    padding-right: 0.5em;
  }
}

.doorbell_form_section__file_size_threshold {
  margin-top: 1em;
}

.doorbell_form_section__file_is_too_large_warning {
  @extend .doorbell_form_section__helper_text;
  margin-top: 1rem;
  color: var(--color-orange-main);
}

.doorbell_form_section__file_input_field {
  display: none !important;
}

.doorbell_form_section__files_selected_for_upload_container {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-300);
  border-radius: 5px;

  div {
    width: 100%;
  }
}

.doorbell_form_section__single_file_selected_for_upload_container {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  i {
    padding-top: 0.2em;
    padding-right: 1em;
  }
}

.doorbell_form_section__file_delimeter {
  border-top: 1px solid var(--color-gray-300);
}

.doorbell_form_section__action_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doorbell_form_section__form__input {
  & textarea::placeholder {
    color: var(--color-gray-300) !important;
    opacity: 1 !important;
  }
}

.doorbell_include_screenshot_container {
  margin-top: 1rem;
}

.doorbell_form__tutorial_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.doorbell_form__tutorial_menu__container {
  max-width: 460px;
}

.doorbell_form__tutorial_menu__link_tile {
  display: block;
  width: 140px;
  height: 90px;
  margin: 8px 0;
  border-radius: 5px;
  background-color: var(--color-gray-50);
  line-height: 90px;
  text-align: center;
  text-decoration: unset;

  &:hover {
    background-color: var(--color-orange-main);
    color: #fff;
    text-decoration: unset;
  }
}

.doorbell_form__tutorial_menu__goto_form_ctrl {
  text-align: center;
  margin-top: 18px;
}
