@charset "UTF-8";

@import "bootstrap/dist/css/bootstrap";
@import "animate.css/animate";

@import "react-spinner/react-spinner";
@import "select";
@import "titles";
@import "leeruniek.font";
@import "src/components/ui/colors";

$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/css/all";

.introjs-helperNumberLayer {
  line-height: 12px;
}

body {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-display: optional;
  font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  background-color: var(--color-gray-50);
  font-size: 13px;
  color: var(--color-black);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Comfortaa", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 10px;
}
h3,
h4,
h5 {
  margin-top: 5px;
  font-weight: 700;
}

a {
  color: var(--color-black);

  &:focus,
  &:active {
    appearance: none;
    outline: 0 !important;
  }

  &:hover {
    text-decoration: underline;
  }

  &.link {
    color: $highlightColor;
    text-decoration: underline;
  }
}

strong {
  font-weight: 700;
}

.actions {
  a {
    &.pull-right {
      margin-left: 5px;
    }
    &.pull-left {
      margin-right: 5px;
    }

    .fa {
      margin-right: 3px;
    }
  }
}

.fa.fa-grip:before {
  content: "\f142\202F\f142";
}

.dc-btn {
  &:focus,
  &:active {
    appearance: none;
    outline: 0 !important;
  }
}

@media all {
  .page-break {
    display: none;
  }
}
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}
@media print {
  html,
  body {
    height: 90%;
  }
  body {
    zoom: 0.85;
  }

  a[href]:after {
    content: none !important;
  }
}

.form-control:focus {
  border-color: var(--color-blue-main) !important;
}

.nav > li.active {
  border-color: var(--color-blue-light);
}

.text-default {
  color: #c2c2c2;
}

.modal-avg {
  width: 450px;
}

/*SENCO dash*/
div .group-bar {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

div .group-bar:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.Splash {
  font-family: Helvetica;
}

.btn {
  border-radius: 5px;

  &:focus,
  &:active {
    appearance: none;
    outline: 0 !important;
  }

  &.btn-primary {
    background: var(--color-teal-main);
    border-color: var(--color-teal-main);

    &:hover {
      background: var(--color-blue-light);
      border-color: var(--color-blue-light);
    }

    &:active {
      background: var(--color-blue-light);
      border-color: var(--color-blue-light);
    }
  }
}

label {
  font-weight: 700;
}
