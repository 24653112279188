@import "src/components/ui/colors.scss";
@import "src/components/ui.module";
@import "./select-scheme-yellow.module.scss";

:root {
  --select-field-font-size: var(--unit);
  --select-field-padding: calc(var(--distancer) / 3);
  --select-field-height: calc(
    var(--select-field-padding) * 2 + var(--select-field-font-size)
  );
  --select-focus-label-top: calc(
    -1 * (var(--select-field-padding) + var(--select-field-font-size))
  );
  --select-text-label-color: var(--color-black);

  /* same as button neutral color */
  --select-arrow-color: color(var(--palette-grey-900) a(40%));
  --select-arrow-color-hover: var(--palette-grey-900);
}

.select {
  position: relative;

  transition-timing-function: var(--animation-curve-default);
  transition-duration: var(--animation-duration);
  transition-property: opacity;
}

.label {
  color: var(--select-text-label-color);

  font-weight: normal;
}

.select--disabled {
  pointer-events: none;

  opacity: 0.6;
}

.select--focused .label {
  top: var(--select-focus-label-top);

  color: var(--color-primary);
}

.select-has-value .label {
  top: var(--select-focus-label-top);
}

.inner-select {
  position: relative;
}

.inner-select :global(.Select-arrow) {
  border-top-color: var(--select-arrow-color);
}

.inner-select :global(.Select-arrow-zone .Select-arrow):hover {
  border-top-color: var(--select-arrow-color-hover);
}

.dropdownIndicator {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}
