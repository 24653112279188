.page-title {
  margin-top: -15px;
  display: flex;

  .icon {
    margin-top: 5px;

    i {
      color: #3dccb4;
      padding-right: 6px;
    }
  }

  .title {
    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
    }

    h4 {
      font-weight: 400;
    }
  }
}
