@import "../../styles/variables.css";
@import "./checkbox-small.module.css";
@import "./checkbox-scheme-yellow.module.css";

:root {
  --checkbox-color: var(--color-primary);
  --checkbox-disabled-color: color-mix(
    in srgb,
    var(--color-black) 26%,
    transparent
  );
  --checkbox-focus-checked-color: color-mix(
    in srgb,
    var(--color-primary) 26%,
    transparent
  );
  --checkbox-size: 20px;
  --checkbox-focus-color: color-mix(
    in srgb,
    var(--color-black) 1%,
    transparent
  );
  --checkbox-focus-size: calc(var(--checkbox-size) * 2.3);
  --checkbox-text-color: var(--color-black);
  --checkbox-text-font-size: var(--font-size);
  --checkbox-total-height: 20px;
  --checkbox-is-checked-color: var(--color-white);
}

.field {
  position: relative;

  display: block;

  box-sizing: border-box;
  height: var(--checkbox-size);

  margin-bottom: 0;

  white-space: nowrap;

  font-size: var(--checkbox-text-font-size);
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  cursor: pointer;
}

.field *,
.field *::after,
.field *::before {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
}

.text {
  display: inline-block;

  padding-left: calc(var(--distancer) / 3);

  vertical-align: top;
  white-space: nowrap;

  color: var(--checkbox-text-color);

  font-weight: var(--font-weight-normal);
  line-height: var(--checkbox-size);
}

.input {
  position: absolute;

  overflow: hidden;

  width: 0;
  height: 0;

  opacity: 0;
}

.input ~ .check::before {
  position: absolute;
  top: 50%;
  left: 50%;

  width: var(--checkbox-focus-size);
  height: var(--checkbox-focus-size);
  margin-top: calc(-1 * var(--checkbox-focus-size) / 2);
  margin-left: calc(-1 * var(--checkbox-focus-size) / 2);

  content: "";
  transform: scale(0);
  pointer-events: none;

  border-radius: 50%;
  background-color: var(--checkbox-focus-color);
}

.check {
  position: relative;

  display: inline-block;

  box-sizing: border-box;
  width: var(--checkbox-size);
  height: var(--checkbox-size);

  cursor: pointer;
  transition-timing-function: var(--animation-curve-default);
  transition-duration: var(--animation-duration);
  transition-property: background-color;
  vertical-align: top;

  border-width: 2px;
  border-style: solid;
  border-color: var(--checkbox-text-color);
  border-radius: 2px;

  font-smoothing: antialiased;
  text-size-adjust: 100%;
}

.check *,
.check *::after,
.check *::before {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
}

.check.checked {
  border-color: var(--checkbox-color);
  background-color: var(--checkbox-color);
}

.check.checked::after {
  position: absolute;
  top: calc(-0.1 * var(--unit));
  left: calc(0.4 * var(--unit));

  width: calc(0.5 * var(--unit));
  height: var(--unit);

  content: "";
  transform: rotate(45deg);
  animation: checkmark-expand 140ms ease-out forwards;

  border-style: solid;
  border-color: var(--checkbox-is-checked-color);
  border-top: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left: 0;
}

.disabled > .text {
  color: var(--checkbox-disabled-color);
}

.disabled > .check {
  cursor: auto;

  border-color: var(--checkbox-disabled-color);
}

.disabled > .check.checked {
  cursor: auto;

  border-color: transparent;
  background-color: var(--checkbox-disabled-color);
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);

    opacity: 1;
  }

  100% {
    transform: scale(1, 1);

    opacity: 0;
  }
}
