.Select-control {
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid color(var(--color-black) a(12%));
  border-left: 0;
  border-radius: 0;

  &:hover {
    box-shadow: 0;
  }
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-bottom: 1px solid var(--palette-teal-400);
  box-shadow: none;
}

.Select-menu-outer {
  border: 1px solid color(var(--color-black) a(12%));
  border-radius: 0;
  box-shadow: none;
}

.Select-outer-menu {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  box-shadow: none;
}

.Select-option:last-child {
  border-radius: 0;
}

.Select-option-group-label ~ .Select-option,
.Select-option-group-label ~ .Select-option-group {
  padding-left: 10px;
}

.Select--multi .Select-value {
  margin-right: 5px;
  margin-left: 0;

  color: #676a6c;
  border-radius: 3px;
}

.Select--multi .Select-value-icon {
  padding: 0 5px;
}

.Select--multi .Select-value-label {
  padding: 3px 7px;
}
