@import "src/components/ui/chalk.scss";

$socialEmotionalPurple: map-get($colors, color-purple-dark);
$pupil-notes: map-get($colors, color-gray-200);
$lightgrey: map-get($colors, color-gray-200);
$lightBorder: map-get($colors, color-gray-200);
$bodyFontColor: map-get($colors, color-black);
$highlightColor: map-get($colors, color-teal-main);
$linkColor: map-get($colors, color-teal-dark);
$headerColor: map-get($colors, color-gray-800);
$lightBackground: map-get($colors, color-gray-50);
$grey: map-get($colors, color-gray-400);
$white: map-get($colors, color-gray-50);
$subjectColors: (
  DMT: map-get($colors, color-red-main),
  RW: map-get($colors, color-blue-main),
  SP: map-get($colors, color-yellow-main),
  TBL: map-get($colors, color-purple-main),
  WST: map-get($colors, color-green-main),
  RvK: map-get($colors, color-blue-main),
  TvK: map-get($colors, color-green-main),
);

$chart-breakpoint: 1220px;

:export {
  @each $name, $value in $colors {
    --#{$name}: #{$value};
  }
  $socialEmotionalPurple: $socialEmotionalPurple;
  $pupil-notes: $pupil-notes;
  $lightgrey: $lightgrey;
  $lightBorder: $lightBorder;
  $bodyFontColor: $bodyFontColor;
  $highlightColor: $highlightColor;
  $linkColor: $linkColor;
  $headerColor: $headerColor;
  $lightBackground: $lightBackground;
  $grey: $grey;
  $white: $white;
  $subjectColors: $subjectColors;
  $chart-breakpoint: $chart-breakpoint;
}
