:root {
  --preferred-font: "Montserrat", Helvetica, Arial, sans-serif;
  --unit: 13px;
  --distancer-large: 35px;
  --distancer: 30px;
  --distancer-medium: 10px;
  --distancer-small: 5px;
  --global-radius: 3px;
  --global-line-height: 1.42857143;
  --unit: 13px;
  --distancer-small: 5px;
  --distancer-medium: 10px;
  --distancer: 30px;
  --font-size: 13px;
  --font-size-tiny: 11px;
  --font-size-small: 12px;
  --font-size-normal: var(--font-size);
  --font-size-medium: 14px;
  --font-size-big: 18px;
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;
  --font-weight-bolder: 700;
  --h3-padding: calc(var(--distancer) / 3);
  --h3-border-width: 2px;
  --h3-height: var(--distancer);
  --h3-content-height: var(--distancer);
  --standard-increment-mobile: calc(5.6 * var(--unit));
  --standard-increment-desktop: calc(6.4 * var(--unit));

  /* Selected colors from react-toolbox library */
  --palette-red-100: rgb(255, 205, 210);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-green-100: rgb(200, 230, 201);

  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);

  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);

  /* Chalk colors */
  --color-white: #fff;
  --color-gray-50: #f5f7f9;
  --color-gray-100: #e7eef4;
  --color-gray-200: #ced9e4;
  --color-gray-300: #b7c1cc;
  --color-gray-400: #9aa5b0;
  --color-gray-500: #8b95a1;
  --color-gray-600: #768392;
  --color-gray-700: #596a7c;
  --color-gray-800: #30445a;
  --color-gray-900: #1f3044;
  --color-black: #13202f;
  --color-red-main: #fe6b6b;
  --color-red-dark: #bb575a;
  --color-red-light: #f7cbcb;
  --color-orange-main: #f5a623;
  --color-orange-dark: #b58128;
  --color-orange-light: #f4ddb6;
  --color-yellow-main: #ffc800;
  --color-yellow-dark: #bc980f;
  --color-yellow-light: #f7e7ab;
  --color-green-main: #b8d916;
  --color-green-dark: #8aa41e;
  --color-green-light: #e2edb2;
  --color-teal-main: #3dccb4;
  --color-teal-dark: #349b8d;
  --color-teal-light: #bce9e1;
  --color-blue-main: #2dace3;
  --color-blue-dark: #2985ae;
  --color-blue-light: #b8dff0;
  --color-purple-main: #a48ad5;
  --color-purple-dark: #7c6da5;
  --color-purple-light: #dcd5eb;

  /* Color aliases */
  --background-content: var(--color-gray-50);
  --color-border-gray: var(--color-gray-200);
  --color-primary: var(--color-teal-main);
  --color-warning: var(--color-orange-main);
  --color-leer-blue: var(--color-blue-main);
  --color-leer-yellow: var(--color-orange-main);
  --color-gray-light: var(--color-gray-50);
  --color-gray-medium-light: var(--color-gray-200);
  --color-gray-medium: var(--color-gray-500);
  --color-gray: var(--color-gray-800);
  --color-petrol: var(--color-gray-900);
  --color-petrol-light: var(--color-gray-800);
  --color-purple: var(--color-purple-dark);
  --color-petrol-dark: var(--color-black);
  --color-primary-dark: var(--color-teal-dark);
  --color-primary-light: var(--color-teal-light);
  --color-dark-yellow: var(--color-orange-main);
  --color-dark-red: var(--color-red-dark);
  --color-subject-DMT: var(--color-red-main);
  --color-subject-RW: var(--color-blue-main);
  --color-subject-SP: var(--color-yellow-main);
  --color-subject-TBL: var(--color-purple-main);
  --color-subject-WST: var(--color-green-main);
  --color-subject-RvK: var(--color-blue-main);
  --color-subject-TvK: var(--color-green-main);
}

/* Orientation */
@custom-media --portrait (orientation: portrait);
@custom-media --landscape (orientation: landscape);

/* Devices (defined by max width) */
@custom-media --xxs-viewport (max-width: 480px);
@custom-media --xs-viewport (max-width: 600px);
@custom-media --sm-tablet-viewport (max-width: 720px);
@custom-media --sm-viewport (max-width: 840px);
@custom-media --md-viewport (max-width: 960px);
@custom-media --lg-tablet-viewport (max-width: 1024px);
@custom-media --lg-viewport (max-width: 1280px);
@custom-media --xl-viewport (max-width: 1440px);
@custom-media --xxl-viewport (max-width: 1600px);
@custom-media --xxxl-viewport (max-width: 1920px);

/* Devices (defined by min-width) */
@custom-media --larger-than-xxs-viewport (min-width: 480px);
@custom-media --larger-than-xs-viewport (min-width: 600px);
@custom-media --larger-than-sm-tablet-viewport (min-width: 720px);
@custom-media --larger-than-sm-viewport (min-width: 840px);
@custom-media --larger-than-md-viewport (min-width: 960px);
@custom-media --larger-than-lg-tablet-viewport (min-width: 1024px);
@custom-media --larger-than-lg-viewport (min-width: 1280px);
@custom-media --larger-than-xl-viewport (min-width: 1440px);
@custom-media --larger-than-xxl-viewport (min-width: 1600px);
@custom-media --larger-than-xxxl-viewport (min-width: 1920px);

/* scss variables for @each etc */

.red {
  color: var(--color-dark-red);
}

.primary {
  color: var(--color-primary);
}

.disabled {
  pointer-events: none;

  opacity: 0.5;
}

.ellipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.hr {
  height: 2px;
  margin-top: calc(var(--distancer) / 2);
  margin-bottom: calc(var(--distancer) / 2);

  border: 0;
  background-color: var(--color-petrol);
}

.hr--active {
  background-color: var(--color-primary);

  composes: hr;
}

.clearfix::before,
.clearfix::after {
  display: table;

  content: " ";
}

.clearfix::after {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.sticky {
  z-index: 101;
}

.padding-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.margin-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/*
 * Forms
 */

.form-item {
  margin-bottom: calc(var(--distancer) / 2);
}

.form-actions {
  margin-top: calc(var(--distancer) / 2);
  padding-top: calc(var(--distancer) / 2);

  border-top: 1px solid var(--color-border-gray);
}

/*
 * Table grid
 */

.table {
  display: table;

  width: 100%;
}

.table__row {
  display: table-row;

  width: 100%;
}

.table__cell,
.table__head {
  display: table-cell;

  vertical-align: top;
}

/**
 * Link
 */

.ui-link {
  text-decoration: underline;

  color: var(--color-black);
}

.ui-link:hover {
  color: var(--color-primary-dark);
}

.ui-link--muted {
  color: var(--palette-grey-600);

  composes: ui-link;
}

/**
 * A box with shadows
 */

.ui-box-with-shadow {
  border-radius: calc(2 * var(--global-radius));
  background: var(--color-white);
  box-shadow: 1px 1px 4px 0
    color-mix(in srgb, var(--color-petrol) 20%, transparent);
}

/**
 * Headings
 */

.ui-h3 {
  height: calc(var(--h3-content-height) + var(--h3-padding));
  margin: 0 0 var(--h3-padding) 0;
  padding: 0 0 var(--h3-padding) 0;

  color: var(--color-petrol);
  border-bottom: var(--h3-border-width) solid var(--color-petrol);

  font-size: var(--font-size-medium);
  line-height: var(--h3-content-height);
}

/**
 * Center growing border
 */

.ui-border-bottom-focus {
  position: relative;
}

.ui-border-bottom-focus::before,
.ui-border-bottom-focus::after {
  position: absolute;
  bottom: 0;

  left: 50%;

  width: 0;
  height: 2px;

  content: "";

  transition-timing-function: var(--animation-curve-default);
  transition-duration: 0.2s;
  transition-property: width, left, background-color;
}

.ui-border-bottom-focus::before {
  left: 0;

  width: 100%;

  background-color: transparent;
}

.ui-border-bottom-focus::after {
  background-color: var(--color-primary);
}

/**
 * DnD drop targets
 */

.dnd-target::before {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  content: " ";

  border: 2px dashed transparent;
  border-radius: var(--global-radius);
}

.dnd-target {
  position: relative;
}

.dnd-target--can-drop::before {
  min-height: var(--distancer);

  border-color: var(--palette-grey-400);
}

.dnd-target--can-drop--is-over::before {
  border-color: var(--color-petrol);
}

/**
 * DnD Preview element
 */

.dnd__preview {
  width: 250px;
  padding: calc(var(--distancer) / 3);

  border-radius: var(--global-radius);
  background: var(--color-white);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.dnd__preview-title {
  padding: var(--distancer-small) 0;

  vertical-align: middle;

  color: var(--color-petrol);
  border-bottom: var(--h3-border-width) solid var(--color-primary);

  font-weight: var(--font-weight-bold);
}

.dnd__preview-grip {
  width: calc(var(--distancer) / 2);

  color: var(--color-gray-medium);
}
