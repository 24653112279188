@import "src/components/ui.module";

/**
 * loggedin.layout.jsx
 */

.page-wrapper {
  min-height: 100vh;
  margin: 0 0 0 220px;

  background-color: var(--background-content);

  @media print {
    margin: 0;
  }
}

.loggedin-wrapper {
  background-color: var(--color-petrol-light);
}

.loggedin-layout {
  max-width: 1440px;
  margin: auto;

  box-shadow: 0 0 69px 25px #1e313a;

  @media print {
    box-shadow: none;
  }
}

/**
 * loggedin__footer.jsx
 */

.footer {
  position: fixed;

  z-index: 101;

  bottom: 0;

  width: calc(100% - 220px);
  max-width: 1220px;

  margin-left: -15px;
  padding-right: 15px;
  padding-bottom: 15px;

  transform: translate3d(0, 0, 0);

  pointer-events: none;

  line-height: 33px;

  @media print {
    width: 100%;
  }
}

.footer__feedback,
.footer__support {
  pointer-events: auto;
}

/**
 * loggedin__header.jsx
 */

.header__page-heading {
  max-width: 1220px;
  height: 90px;

  background-color: var(--color-white);

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);

  & h4 {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
  }

  @media print {
    margin-left: -260px !important;

    border-bottom: 0 !important;
  }
}

.header__page-heading h2 {
  @media print {
    margin-top: 7px;
  }
}

.header__page-heading h3 {
  @media print {
    font-size: 25px;
  }
}

.header__submenu {
  text-align: right;

  @media print {
    display: none;
  }
}

.header__link {
  display: block;

  margin-bottom: var(--distancer-small);

  text-decoration: underline;

  font-size: var(--font-size-medium);
  cursor: pointer;
}

.header__link i {
  margin-right: calc(var(--distancer-small) * 2);
}

.header__link--user {
  color: var(--color-petrol);

  composes: header__link;
}

.header__link--logout {
  color: var(--color-gray);

  composes: header__link;
}

.header__impersonation-info {
  text-align: right;
  position: absolute;
  right: 100px;
}

.user-layout__page-content {
  padding: var(--distancer);
}
