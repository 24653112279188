@import "src/components/ui.module";

.setup-guide {
  text-align: left;

  ol {
    padding: 1em 3em;
  }
}

.qr-code {
  margin-bottom: var(--distancer);
}

.code-input {
  width: 6em;

  input {
    font-size: 140%;
    text-align: center;
  }
}

.error {
  color: var(--color-red-main);
  margin-top: 1rem;
}

.support-link {
  display: block;
  composes: ui-link--muted;
}

.support-link-container {
  margin-top: 0.2rem;
  margin-bottom: var(--distancer);
  text-align: center;
}
