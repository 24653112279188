@import "src/components/ui/colors.scss";
@import "src/components/ui.module";

:root {
  --yellow-select-text-label-color: var(--color-black);
  --yellow-select-text-highlight-color: var(--color-dark-yellow);

  /* same as button neutral color */
  --yellow-select-arrow-color: color(var(--palette-grey-900) a(40%));
  --yellow-select-arrow-color-hover: var(--palette-grey-900);
}

.yellow .label {
  color: var(--yellow-select-text-label-color);
}

.yellow.select-has-focus .label {
  color: var(--yellow-select-text-highlight-color);
}

.yellow .inner-select :global(.Select-arrow) {
  border-top-color: var(--yellow-select-arrow-color);
}

.yellow .inner-select :global(.Select-option.is-selected) {
  color: #333;
  background-color: color(var(--yellow-select-text-highlight-color) a(6%));
}

.yellow .inner-select :global(.Select-option.is-focused) {
  color: #333;
  background-color: color(var(--yellow-select-text-highlight-color) a(12%));
}

.yellow .inner-select :global(.Select-arrow-zone .Select-arrow):hover {
  border-top-color: var(--yellow-select-arrow-color-hover);
}

.yellow.select-has-focus
  .inner-select
  :global(.is-focused:not(.is-open) > .Select-control) {
  border-bottom-color: var(--yellow-select-text-highlight-color);
  box-shadow: none;
}
